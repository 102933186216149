<template>
  <div
    class="cr-kanban-wrapper"
    :class="{ 'cr-kanban-drag': dragMode }"
    v-drag-and-drop="{
      DRAG_COMP: 'kanban',
      genHelper,
      dragstart,
      genDropzone,
      markDropzone,
      mouseup
    }"
  >
    <KanbanToolbar
      :statusColumns="statusColumns"
      :selectedColumn.sync="selectedColumn"
      @toggleSetting="showSetting = !showSetting"
    />

    <div id="todoList" class="cr-kanban-container">
      <KanbanList :statusColumn="statusColumn" />
      <KanbanSetting v-if="showSetting" />
    </div>

    <div v-if="!selectedColumn" class="cr-empty">
      <h3>상태 컬럼이 없습니다.</h3>
    </div>

    <Menu />
    <Announce />
  </div>
</template>

<style lang="scss">
.cr-kanban-ghost {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
    0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
  .cr-kanban-label {
    margin-right: 0px;
  }
  .cr-kanban-item {
    margin-top: 0px;
  }
}
</style>
<style lang="scss" scoped>
.cr-kanban-wrapper {
  position: relative;
  height: calc(100% - 66px);

  &.cr-kanban-drag::v-deep {
    .cr-kanban-label {
      cursor: grabbing !important;
      * {
        cursor: grabbing !important;
      }
    }
  }

  .cr-kanban-container {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .cr-empty {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 240px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import KanbanToolbar from "./kanban/toolbar";
import KanbanList from "./kanban/list";
import KanbanSetting from "./kanban/setting";
import Menu from "./group/Menu.vue";

import Announce from "./kanban/Announce";

export default {
  components: { KanbanToolbar, KanbanList, KanbanSetting, Menu, Announce },
  async mounted() {
    const [status = {}] = this.statusColumns;
    this.selectedColumn = status.value;

    // 임시처리
    await this.getKanbanColumns(this.routeBoardId);
  },
  data() {
    return { selectedColumn: "", showSetting: false };
  },
  computed: {
    ...mapGetters("todoItem", ["items"]),
    ...mapGetters("todoHeader", ["header"]),
    ...mapGetters("todoKanban", ["divideGroup"]),
    ...mapGetters("todoRoute", ["routeBoardId"]),
    ...mapGetters("dragDrop", ["dragComp", "dragMode"]),
    statusColumns() {
      return this.header.filter(h => h.type === "STATUS");
    },
    statusColumn() {
      return this.statusColumns.find(s => s.value === this.selectedColumn);
    }
  },
  methods: {
    ...mapActions("todoItem", ["updateItem"]),
    ...mapActions("todoHeader", ["updateHeader"]),
    ...mapActions("todoKanban", ["getKanbanColumns"]),
    // e, setDragInfo
    genHelper(e, setDragInfo) {
      const dragTarget = e.target.closest(".cr-draggable-kanban");
      if (!dragTarget) return null;

      const { type } = dragTarget.dataset;
      const dragInfo = { type, target: dragTarget };
      let cloneTarget = dragTarget.cloneNode(true);
      if (type === "label") {
        const target = e.target.closest(".cr-kanban-label");
        if (!target) return null;

        dragInfo["target"] = target;
        cloneTarget = target.cloneNode(true);
      }
      setDragInfo(dragInfo);

      // 마우스 따라다니는 고스트
      const ghost = document.createElement("div");
      ghost.classList.add("cr-kanban-ghost");
      ghost.appendChild(cloneTarget);
      return ghost;
    },
    dragstart(e, setDragInfo, dragInfo) {
      const { target, type } = dragInfo;
      if (type === "item") {
        const { height, width } = target.getBoundingClientRect();

        // 아이템 목록에 표시해줄 고스트
        dragInfo["ghost"] = document.createElement("div");
        Object.assign(dragInfo.ghost.style, {
          width: `${width}px`,
          minHeight: `${height}px`,
          marginTop: "10px",
          border: "4px solid var(--v-primary-base)",
          borderStyle: "dashed",
          backgroundColor: "transparent"
        });
      }

      document.body.classList.add("no-drop");
      target.classList.add("cr-drag-target");
    },
    genDropzone() {},
    markDropzone(e, dragInfo) {
      if (this.dragComp !== "kanban") return;

      const { type, target: dragTarget } = dragInfo;
      switch (type) {
        case "label": {
          const list = e.target.closest(".cr-label-list-wrapper");
          if (!list) return;

          const dropTarget = e.target.closest(".cr-kanban-label");
          if (dropTarget && dragTarget != dropTarget) {
            const { gi: dragGI } = dragTarget.dataset;
            const { gi: dropGI } = dropTarget.dataset;
            if (this.divideGroup && dragGI !== dropGI) return;

            dragInfo["dropTarget"] = dropTarget;
            const dropTargetIndex = list.children.indexOf(dropTarget);
            const dragTargetIndex = list.children.indexOf(dragTarget);

            if (dragTargetIndex < dropTargetIndex) {
              dragInfo["position"] = "after";
              const { nextSibling } = dropTarget;
              if (!nextSibling) return list.appendChild(dragTarget);

              list.insertBefore(dragTarget, nextSibling);
            }

            if (dragTargetIndex > dropTargetIndex) {
              dragInfo["position"] = "before";
              list.insertBefore(dragTarget, dropTarget);
            }
          }
          break;
        }

        case "item": {
          if (e.target == dragInfo.ghost) return;
          const dropTarget = e.target.closest(".cr-kanban-label");
          dragInfo["dropTarget"] = dropTarget;

          const items = e.target.closest(".cr-kanban-label-items");
          if (!items) return (dragInfo["dropTarget"] = "");
          break;
        }
        // *************************************아이템별 순서 처리**************************************** //
        // case "item": {
        //   if (e.target == dragInfo.ghost) return;

        //   const dropTarget = e.target.closest(".cr-kanban-label");
        //   dragInfo["dropTarget"] = dropTarget;
        //   const item = e.target.closest(".cr-kanban-item-wrapper");
        //   if (!item) {
        //     const emptyArea = e.target.closest(".empty-area");
        //     if (!emptyArea) return (dragInfo["dropTarget"] = "");

        //     dragInfo.ghost?.parentNode?.removeChild(dragInfo.ghost);
        //     dragInfo.ghost.style.marginTop = "0px";
        //     const items = emptyArea.closest(".cr-kanban-label-items");
        //     if (!items.querySelectorAll(".cr-kanban-item-wrapper")?.length) {
        //       dragInfo.ghost.style.marginTop = "10px";
        //     }

        //     return emptyArea.before(dragInfo.ghost);
        //   }

        //   const { height: itemHeight } = item.getBoundingClientRect();
        //   if (e.offsetY < itemHeight / 2) {
        //     dragInfo.ghost?.parentNode?.removeChild(dragInfo.ghost);
        //     dragInfo.ghost.style.marginTop = "10px";

        //     return item.before(dragInfo.ghost);
        //   }

        //   dragInfo.ghost.style.marginTop = "10px";
        //   if (item.classList.contains("last-item")) {
        //     dragInfo.ghost.style.marginTop = "0px";
        //   }

        //   dragInfo.ghost?.parentNode?.removeChild(dragInfo.ghost);
        //   item.after(dragInfo.ghost);
        //   break;
        // }
        default:
          break;
      }
    },
    mouseup(e, dragInfo) {
      const { type, target, dropTarget, position, ghost } = dragInfo;
      if (ghost) ghost?.parentNode?.removeChild(ghost);
      document.body.classList.remove("no-drop");
      target.classList.remove("cr-drag-target");

      if (!dropTarget) return;
      switch (type) {
        case "label": {
          let { labels, value: headerValue } = this.statusColumn;
          labels = [...labels];

          const dragValue = target.dataset.value;
          const dropValue = dropTarget.dataset.value;
          const dragIndex = labels.findIndex(({ value }) => value == dragValue);
          let tmp = labels[dragIndex];
          labels.splice(dragIndex, 1);
          let dropIndex = labels.findIndex(({ value }) => value == dropValue);
          if (position == "after") dropIndex += 1;

          labels.splice(dropIndex, 0, tmp);
          this.updateHeader({
            mutation: "CHANGE_HEADER",
            boardId: this.routeBoardId,
            params: {
              value: headerValue,
              header: { ...this.statusColumn, labels }
            }
          });
          break;
        }
        case "item": {
          const { value: itemValue } = dropTarget.dataset;
          const { id: itemId, gi: groupId } = target.dataset;
          const [item] = this.items[groupId]?.filter(i => i.id == itemId) || [];
          if (!item) return;

          const { parentId, boardId } = item;
          const { value: headerValue } = this.statusColumn;
          this.updateItem({
            itemId,
            parentId,
            groupId,
            boardId,
            headerValue,
            itemValue,
            prevValue: item[headerValue]
          });
          break;
        }
        default:
          break;
      }
    }
  }
};
</script>
