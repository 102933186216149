<template>
  <v-dialog v-model="dialogShow" width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">칸반 알파 테스트</span>
      </v-card-title>
      <v-card-text style="color:black;">
        <div style="font-weight:bold;">업데이트 예정 사항</div>
        <div class="grey--text">
          칸반보드는 현재 "크리니티"에서만 사용할 수 있습니다.
        </div>
        <ol style="padding-left:18px; margin-top:4px; line-height:2;">
          <li>아이템 상/하 순서 변경 기능 추가</li>
          <li>각 상태에 따른 아이템 순서 저장 기능</li>
          <li>칸반 뷰 모드에서 아이템 추가 기능</li>
          <li>뷰 내에서 속성 아이템 정렬 기능</li>
          <li>칸반 성능 개선 (가상화 적용 에정)</li>
        </ol>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogShow = false">
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogShow: true
    };
  }
};
</script>
