<template>
  <div class="cr-title" :title="title" @click="$emit('update:editable', true)">
    <span>{{ title }}</span>
  </div>
</template>

<style lang="scss" scoped>
.cr-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
  text-overflow: unset;
  white-space: break-spaces;

  cursor: text;
  margin-top: 2px;
  padding: 4px 4px 1px;
  line-height: 18px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: 0.0178571429em !important;

  &:hover {
    border-color: rgba(0, 0, 0, 0.18);
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
