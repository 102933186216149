<template>
  <div class="cr-kanban-toolbar">
    <div class="cr-kanban-toolbar-left">
      <Status
        v-bind="$props"
        v-for="status in statusColumns"
        :key="status.value"
        :status="status"
        :isSelected="selectedColumn === status.value"
        @click="$emit('update:selectedColumn', status.value)"
      />
    </div>

    <div class="cr-kanban-toolbar-right">
      <v-btn icon small @click="$emit('toggleSetting')">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 4px 4px 0px 4px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .cr-kanban-toolbar-left {
    display: flex;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border: none;
    }
  }
  .cr-kanban-toolbar-right {
    display: flex;
    padding: 0px 10px;
  }
}
</style>

<script>
import Status from "./Status.vue";

export default {
  components: { Status },
  props: {
    statusColumns: {
      type: Array,
      default: () => []
    },
    selectedColumn: {
      type: String,
      default: ""
    }
  }
};
</script>
