<template>
  <div class="cr-text-wrapper">
    <DisplayText
      ref="displayText"
      v-if="!editable"
      v-bind="$props"
      :title="title"
      :editable.sync="editable"
    />
    <EditText
      v-else
      v-bind="$props"
      :rows="rows"
      :title="title"
      :editable.sync="editable"
    />

    <DetailBtn v-bind="$props" />
  </div>
</template>

<style lang="scss" scoped>
.cr-text-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
</style>

<script>
import { mapGetters } from "vuex";
import DisplayText from "./DisplayText.vue";
import EditText from "./EditText.vue";
import DetailBtn from "./DetailBtn.vue";

export default {
  components: { DisplayText, EditText, DetailBtn },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { offsetHeight: height } = this.$refs.displayText.$el;
      this.rows = Math.round(height / 24);
    });
  },
  data() {
    return { editable: false, rows: 1 };
  },
  watch: {
    title() {
      this.$nextTick(() => {
        const { offsetHeight: height } = this.$refs.displayText.$el;
        this.rows = Math.round(height / 24);
      });
    }
  },
  computed: {
    ...mapGetters("todoHeader", ["titleHeader"]),
    title() {
      return this.item[this.titleHeader.value];
    }
  }
};
</script>
