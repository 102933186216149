<template>
  <v-btn
    small
    rounded
    depressed
    :color="color"
    class="mx-1 px-2"
    @click="$emit('click')"
  >
    {{ status.title }}
  </v-btn>
</template>

<script>
export default {
  props: {
    status: {
      type: Object,
      default: () => ({})
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    color() {
      return this.isSelected ? "primary" : "";
    }
  }
};
</script>
