var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop",value:({
    DRAG_COMP: 'kanban',
    genHelper: _vm.genHelper,
    dragstart: _vm.dragstart,
    genDropzone: _vm.genDropzone,
    markDropzone: _vm.markDropzone,
    mouseup: _vm.mouseup
  }),expression:"{\n    DRAG_COMP: 'kanban',\n    genHelper,\n    dragstart,\n    genDropzone,\n    markDropzone,\n    mouseup\n  }"}],staticClass:"cr-kanban-wrapper",class:{ 'cr-kanban-drag': _vm.dragMode }},[_c('KanbanToolbar',{attrs:{"statusColumns":_vm.statusColumns,"selectedColumn":_vm.selectedColumn},on:{"update:selectedColumn":function($event){_vm.selectedColumn=$event},"update:selected-column":function($event){_vm.selectedColumn=$event},"toggleSetting":function($event){_vm.showSetting = !_vm.showSetting}}}),_c('div',{staticClass:"cr-kanban-container",attrs:{"id":"todoList"}},[_c('KanbanList',{attrs:{"statusColumn":_vm.statusColumn}}),(_vm.showSetting)?_c('KanbanSetting'):_vm._e()],1),(!_vm.selectedColumn)?_c('div',{staticClass:"cr-empty"},[_c('h3',[_vm._v("상태 컬럼이 없습니다.")])]):_vm._e(),_c('Menu'),_c('Announce')],1)}
var staticRenderFns = []

export { render, staticRenderFns }