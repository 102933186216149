<template>
  <v-btn
    icon
    small
    class="cr-tooltip"
    :color="existDetail ? 'primary' : 'grey'"
    @click.stop="openDetail"
    @mouseover="mouseover"
    @mouseout="HIDE_TOOLTIP"
  >
    <v-icon size="15">mdi-comment-text-multiple-outline</v-icon>
  </v-btn>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoRoute", ["routeState"]),
    existDetail() {
      const { existContent, checkListCnt, attachCnt, commentCnt } = this.item;
      return !!existContent || !!checkListCnt || !!attachCnt || !!commentCnt;
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    ...mapActions("todoRoute", ["routeBoardAction"]),
    mouseover(event) {
      const { checkListCnt, attachCnt, commentCnt } = this.item;
      let msg = "";
      // 체크리스트: n개 | 첨부파일: n개 | 댓글: n개
      if (checkListCnt > 0) {
        msg += `체크리스트: ${checkListCnt}개`;
      }
      if (attachCnt > 0) {
        if (msg.length > 0) msg += " | ";
        msg += `첨부파일: ${attachCnt}개`;
      }
      if (commentCnt > 0) {
        if (msg.length > 0) msg += " | ";
        msg += `댓글: ${commentCnt}개`;
      }
      if (isBlank(msg)) {
        msg = "상세화면 보기";
      }

      this.SET_TOOLTIP({ msg, event, position: "top" });
    },
    openDetail() {
      const { workspaceId, boardId, filterId, viewType } = this.routeState;
      const { id: itemId, groupId, parentId } = this.item;

      this.routeBoardAction({
        workspaceId,
        boardId,
        actionObj: JSON.stringify({
          filterId,
          groupId,
          itemId,
          parentId,
          viewType
        })
      });
    }
  }
};
</script>
