<template>
  <div class="cr-kanban-label-items">
    <Item
      v-for="(item, idx) in _items"
      :key="item.id"
      :item="item"
      :isLast="idx == _items.length - 1"
    />

    <div class="empty-area"></div>
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-label-items {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  overflow: hidden scroll;
  padding-left: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  &::-webkit-scrollbar {
    width: 11px;
  }

  .empty-area {
    display: flex;
    flex-grow: 1;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Item from "./item";

export default {
  components: { Item },
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: Object,
      default: () => ({})
    },
    statusColumn: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoItem", ["items"]),
    ...mapGetters("todoKanban", ["divideGroup"]),
    _items() {
      const items = [];
      const { value: labelValue, defaultLabel } = this.label;
      const { value: statusValue } = this.statusColumn;
      Object.keys(this.items).forEach(key => {
        if (this.divideGroup && key != this.group.id) return;

        this.items[key].forEach(i => {
          if (!i[statusValue] && defaultLabel) {
            return items.push(i);
          }

          if (!i[statusValue]) return;
          if (i[statusValue] !== labelValue) return;

          items.push(i);
        });
      });

      return items;
    }
  }
};
</script>
