import { render, staticRenderFns } from "./EditText.vue?vue&type=template&id=f950583c&scoped=true&"
import script from "./EditText.vue?vue&type=script&lang=js&"
export * from "./EditText.vue?vue&type=script&lang=js&"
import style0 from "./EditText.vue?vue&type=style&index=0&id=f950583c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f950583c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
