<template>
  <v-textarea
    autofocus
    outlined
    hide-details
    no-resize
    dense
    auto-grow
    row-height="19"
    :rows="rows"
    :value="title"
    @blur="enter"
    @keydown.enter="enter"
    @keydown.esc="$emit('update:editable', false)"
  />
</template>

<style lang="scss" scoped>
.v-textarea.v-input.v-text-field::v-deep {
  .v-input__slot {
    padding: 5px 5px 0px !important;
    margin-top: 2px;
    min-height: 25px !important;

    fieldset {
      border-radius: 4px !important;
    }
    .v-text-field__slot {
      margin-right: 0px !important;
      max-height: 57px !important;
      overflow-y: auto;

      textarea {
        margin-top: 0px;
        min-height: 20px;
        line-height: 18px;
        padding: 0px !important;
        font-size: 0.875rem !important;
        font-weight: 400 !important;
        letter-spacing: 0.0178571429em !important;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    rows: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoHeader", ["titleHeader"])
  },
  methods: {
    ...mapActions("todoItem", ["updateItem"]),
    async enter(e) {
      this.$emit("update:editable", false);
      let { value: itemValue } = e.target;
      itemValue = itemValue
        .trim()
        .replace(/\r\n/gi, "")
        .replace(/\\n/gi, "")
        .replace(/\n/gi, "");

      if (isBlank(itemValue) || this.title == itemValue) return;

      const { value: headerValue } = this.titleHeader;
      const { boardId, groupId, id: itemId, parentId } = this.item;
      await this.updateItem({
        boardId,
        groupId,
        itemId,
        parentId,
        headerValue,
        itemValue,
        prevValue: this.title
      });
    }
  }
};
</script>
