<template>
  <div class="cr-kanban-list" :class="{ 'cr-divide-group': divideGroup }">
    <GroupList v-if="divideGroup" v-bind="$props" />
    <LabelList v-else v-bind="$props" />
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-list {
  display: flex;
  width: 100%;
  padding: 10px 0px 10px 15px;
  overflow: scroll hidden;
  &.cr-divide-group {
    padding-top: 0px;
    flex-direction: column;
    overflow: scroll;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import GroupList from "./groupList";
import LabelList from "./labelList";

export default {
  components: { GroupList, LabelList },
  props: {
    statusColumn: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoKanban", ["divideGroup"])
  }
};
</script>
