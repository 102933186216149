<template>
  <div class="cr-label-list-wrapper">
    <Label
      v-bind="$props"
      v-for="label in labels"
      :key="label.value"
      :label="label"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-label-list-wrapper {
  display: flex;
}
</style>

<script>
import Label from "./Label.vue";

export default {
  components: { Label },
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    statusColumn: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    labels() {
      const { labels } = this.statusColumn;
      return labels || [];
    }
  }
};
</script>
