<template>
  <div
    data-type="label"
    class="cr-kanban-label-header cr-draggable-kanban"
    :style="style"
  >
    {{ label.title || "공백" }}
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-label-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: grab;
}
</style>

<script>
import { invertColor } from "@/commons/utils/invertColor";

export default {
  props: {
    label: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    style() {
      const { color } = this.label;
      return `background-color: ${color}; color: ${invertColor(color)}`;
    }
  }
};
</script>
