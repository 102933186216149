<template>
  <div class="cr-group-wrapper" :class="{ 'cr-closed': isClosed }">
    <GroupTitle v-bind="$props" />
    <LabelList v-if="!isClosed" v-bind="$props" />
  </div>
</template>

<style lang="scss" scoped>
.cr-group-wrapper::v-deep {
  display: flex;
  flex-direction: column;
  height: 420px;
  max-height: 420px;
  min-height: 420px;
  &.cr-closed {
    min-height: auto;
  }
  .cr-group-title-wrapper {
    position: relative;
    padding-left: 0px;
    min-height: 48px;
  }

  .cr-label-list-wrapper {
    height: 100%;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import GroupTitle from "@/todo/views/components/main/group/title";
import LabelList from "../labelList";

export default {
  components: { GroupTitle, LabelList },
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    statusColumn: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoGroup", ["closedGroup"]),
    isClosed() {
      return this.closedGroup[this.group.id];
    }
  }
};
</script>
