<template>
  <v-list subheader flat outlined>
    <v-subheader>카드 컬럼</v-subheader>

    <AllSelectColumn />
    <v-list-item-group v-model="_columns" multiple>
      <Column v-for="header in headers" :key="header.value" :header="header" />
    </v-list-item-group>
  </v-list>
</template>

<style lang="scss" scoped>
.v-list {
  margin: 5px 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import AllSelectColumn from "./AllSelectColumn.vue";
import Column from "./Column.vue";

export default {
  components: { AllSelectColumn, Column },
  computed: {
    ...mapGetters("todoRoute", ["routeBoardId"]),
    ...mapGetters("todoKanban", ["columns", "headers"]),
    _columns: {
      get() {
        const { columns, headers } = this;
        return columns.map(v => headers.findIndex(h => h.value === v));
      },
      set(_columns) {
        const columns = _columns.map(idx => this.headers[idx].value);
        this.updateColumns({ boardId: this.routeBoardId, columns });
      }
    }
  },
  methods: {
    ...mapActions("todoKanban", ["updateColumns"])
  }
};
</script>
