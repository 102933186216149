<template>
  <div class="cr-kanban-setting">
    <div class="d-flex align-center pa-2">
      <v-icon small>mdi-cog-outline</v-icon>
      <span class="caption pl-1">설정</span>
    </div>

    <div class="cr-scroll">
      <Columns />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-setting {
  width: 300px;
  min-width: 300px;
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.12);

  .cr-scroll {
    height: calc(100% - 36px);
    overflow-y: auto;
  }
}
</style>

<script>
import Columns from "./columns";

export default {
  components: { Columns }
};
</script>
