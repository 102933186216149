<template>
  <div style="display: contents;">
    <Group
      v-bind="$props"
      v-for="group in groups"
      :key="group.id"
      :group="group"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Group from "./Group.vue";

export default {
  components: { Group },
  props: {
    statusColumn: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoGroup", ["groups"])
  }
};
</script>
