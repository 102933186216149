<template>
  <div>
    <div
      v-for="header in displayHeaders"
      :key="header.value"
      class="cr-item-wrapper"
    >
      <div class="cr-item-title">
        <v-icon size="16"> {{ headerIcons[header.type] }}</v-icon>
        <span class="cr-title" :title="header.title">
          {{ header.title }}
        </span>
      </div>
      <component
        :is="$props.components[header.type]"
        :item="item"
        :group="group"
        :header="header"
        :isKanban="true"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-item-wrapper::v-deep {
  display: flex;
  height: 40px;
  margin: 10px 0px;
  background-color: #f9f9f9;

  .cr-item-title {
    display: flex;
    align-items: center;
    width: 70px;
    min-width: 70px;
    background-color: #fff;
    font-size: 0.75rem !important;
    font-weight: 400;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;
    .cr-title {
      margin-left: 4px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }

  .cr-item-kanban {
    flex-grow: 1;
    &.cr-item-created-log {
      display: flex;
      align-self: center;
      > span {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import PERSON from "@/todo/views/components/main/group/body/type/Person.vue";
import STATUS from "@/todo/views/components/main/group/body/type/Status.vue";
import TIMELINE from "@/todo/views/components/main/group/body/type/Timeline.vue";
import CREATED_LOG from "@/todo/views/components/main/group/body/type/CreatedLog.vue";
import TEXT from "@/todo/views/components/main/group/body/type/Text.vue";
import NUMBER from "@/todo/views/components/main/group/body/type/Number.vue";
import LINK from "@/todo/views/components/main/group/body/type/Link.vue";
import CHECK from "@/todo/views/components/main/group/body/type/Check.vue";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    components: {
      type: Object,
      default: () => ({
        PERSON,
        STATUS,
        TIMELINE,
        CREATED_LOG,
        TEXT,
        NUMBER,
        LINK,
        CHECK
      })
    }
  },
  computed: {
    ...mapGetters("todoGroup", ["orgGroups"]),
    ...mapGetters("todoKanban", ["displayHeaders", "headerIcons"]),
    group() {
      const { groupId } = this.item;
      return this.orgGroups.find(g => g.id == groupId);
    }
  }
};
</script>
