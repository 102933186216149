<template>
  <div class="cr-kanban-item-wrapper" :class="{ 'last-item': isLast }">
    <div
      :data-id="item.id"
      :data-gi="item.groupId"
      data-type="item"
      class="cr-kanban-item cr-draggable-kanban"
    >
      <Title v-bind="$props" />
      <Items v-bind="$props" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-item-wrapper {
  display: flex;
  align-items: flex-end;
  background-color: transparent;
  &.last-item {
    padding-bottom: 10px;
  }
}

.cr-kanban-item {
  cursor: grab;
  width: 240px;
  padding: 6px;
  min-height: 38px;
  margin-top: 10px;
  background-color: #fff;
  border: thin solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &.cr-drag-target {
    display: none;
  }
}
</style>

<script>
import Title from "./title";
import Items from "./Items.vue";

export default {
  components: { Title, Items },
  props: {
    label: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isLast: {
      type: Boolean,
      default: false
    }
  }
};
</script>
