<template>
  <v-list-item>
    <template v-slot:default="{ active }">
      <v-list-item-icon>
        <v-icon color="primary">
          {{ headerIcons[header.type] }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title :title="header.title">
          {{ header.title }}
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-checkbox :input-value="active" color="primary" />
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    header: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoKanban", ["headerIcons"])
  }
};
</script>
