<template>
  <div :data-gi="group.id" :data-value="label.value" class="cr-kanban-label">
    <Header v-bind="$props" />
    <Items v-bind="$props" />
  </div>
</template>

<style lang="scss" scoped>
.cr-kanban-label {
  position: relative;
  width: 260px;
  min-width: 260px;
  min-height: 100px;
  margin-right: 15px;
  background-color: #f9f9f9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.08);
  }
  &.cr-drag-target {
    border: 4px solid var(--v-primary-base);
    border-style: dashed;
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: #f9f9f9;
    }
  }
}
</style>

<script>
import Header from "./header";
import Items from "./items";

export default {
  components: { Header, Items },
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: Object,
      default: () => ({})
    },
    statusColumn: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
