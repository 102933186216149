<template>
  <v-list-item class="cr-all-select" @click="click">
    <v-list-item-content>
      <v-list-item-title>{{ "이 보드의 모든 컬럼" }}</v-list-item-title>
    </v-list-item-content>

    <v-list-item-action>
      <v-checkbox
        color="primary"
        :input-value="active"
        :indeterminate="indeterminate"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<style lang="scss" scoped>
.cr-all-select {
  cursor: pointer;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  mounted() {
    if (!this.columns.length) return;
    this.active = this.columns.length === this.headers.length;
  },
  data() {
    return { active: false };
  },
  computed: {
    ...mapGetters("todoRoute", ["routeBoardId"]),
    ...mapGetters("todoKanban", ["columns", "headers"]),
    indeterminate() {
      const {
        columns: { length },
        headers: { length: hLength }
      } = this;
      return length > 0 && length !== hLength;
    }
  },
  watch: {
    columns(columns) {
      if (!columns.length) return;
      this.active = columns.length === this.headers.length;
    }
  },
  methods: {
    ...mapActions("todoKanban", ["updateColumns"]),
    click() {
      this.active = !this.active;
      let columns = [];
      if (this.active) columns = this.headers.map(h => h.value);

      this.updateColumns({ boardId: this.routeBoardId, columns });
    }
  }
};
</script>
